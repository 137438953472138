import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import ManagerPage from './pages/ManagerPage';
import EmployeePage from './pages/EmployeePage';
import AddEmployee from './pages/AddEmployee';
import EditEmployee from './pages/EditEmployeePage';
import AddCustomer from './pages/AddCustomerPage';
import EditCustomer from './pages/EditCustomerPage';

class App extends React.Component {
  state = {
    isAuthenticated: false,
    user: null,
  };

  setUser = (user) => {
    this.setState({ isAuthenticated: true, user });
  };

  render() {
    const { isAuthenticated, user } = this.state;
    const userRole = user ? user.role : null;

    return (
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage setUser={this.setUser} />} />
          <Route path="/manager" element={isAuthenticated && userRole === 'manager' ? <ManagerPage /> : <Navigate to="/login" />} />
          <Route path="/employee" element={isAuthenticated && userRole === 'employee' ? <EmployeePage /> : <Navigate to="/login" />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/add-employee" element={<AddEmployee />} />
          <Route path="/edit-employee/:employeeId" element={<EditEmployee />} />
          <Route path="/add-customer" element={<AddCustomer />} />
          <Route path="/edit-customer/:customerId" element={<EditCustomer />} /> {/* Update this line */}
        </Routes>
      </Router>
    );
  }
}

export default App;
