import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Grid,
  LinearProgress,
  makeStyles,
  IconButton,
  Button
} from '@material-ui/core';
import axios from 'axios';
import CustomAppBar from '../../AppBar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate } from 'react-router-dom';

function ManagerDashboard() {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderRadius: theme.shape.borderRadius, // Add rounded corners to the table
        overflow: 'hidden', // Hide any content that overflows the table
      },
      appBar: {
        marginBottom: theme.spacing(3),
        width: '100vw', // Make the AppBar take the full width of the screen
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Set a transparent color
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a bottom shadow
        color: theme.palette.getContrastText('#2196F3'),
        position: 'fixed', // Fix the AppBar at the top
        left: 0, // Align the AppBar to the left edge
      },
      content: {
        paddingTop: theme.spacing(10), // Add space between AppBar and table
      },
      background: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundImage: 'url(/background.jpg)',
        backgroundSize: 'cover',
        zIndex: -1, // Place it behind other content
      },
      logo: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      card: {
        padding: theme.spacing(3),
        textAlign: 'center', // Center align text in the card
        borderRadius: theme.shape.borderRadius, // Round the corners of the card
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      cardContent: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10), // Add bottom padding
      },
      progressCell: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
      chip: {
        margin: theme.spacing(0.5),
        transition: '0.2s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
      tableHeaderCell: {
        fontSize: '1.1rem',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        borderRadius: theme.shape.borderRadius, // Add rounded corners to the table header cells
      },
      appbarButton: {
        padding: '6px 12px',
        fontSize: '1.2rem',
        color: 'black',
        background: 'none',
        textDecoration: 'none',
        marginLeft: theme.spacing(1),
        transition: 'color 0.3s',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      progress: {
        flexGrow: 1,
      },
      list: {
        listStyle: 'none',
        padding: 0,
        maxWidth: 600,
        margin: 'auto',
        paddingTop: theme.spacing(10),
      },
      listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '&:last-child': {
          marginBottom: 0,
        },
      },
      customerInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
      },
      button: {
        fontSize:'1.1rem',
        margin: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        marginTop: theme.spacing(2),
        textTransform: 'none', // Prevent uppercase transformation
        fontWeight: 'bold',
      },
      circularProgress: {
        position: 'relative',
        marginBottom: theme.spacing(2),
      },
      progressLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      formContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%', // Or you could give a specific width
        maxWidth: '360px', // Set a max-width for larger screens
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
      },
      input: {
        width: '100%',
        margin: theme.spacing(1, 0),
      },
      submitButton: {
        fontWeight:'bold',
        fontSize: '1.2rem' ,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1.5),
        width: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.primary.dark,
        },
      },
      loginLogo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%', 
      },
      container: {
        height: '100vh', // Use 100% of the viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
      },
      paper: {
        padding: theme.spacing(4),
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          width: '60%', // Set a max width for larger screens
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      searchBar: {
        marginBottom: theme.spacing(3),
        maxWidth: '600px', // Set a max-width for the search bar
        margin: '0 auto', // Center the search bar
        display: 'flex', // Use flex layout
        alignItems: 'center', // Vertically center align items
        borderRadius: theme.shape.borderRadius, // Rounded corners for the input
        background: theme.palette.common.white,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: theme.palette.primary.main, // Border color when the component is idle
          },
          '&:hover fieldset': {
            borderColor: theme.palette.primary.dark, // Border color when hovered
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main, // Border color when the component is focused
          },
        },
      },
      searchInput: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
      },
      
    }));
    const classes = useStyles();
    const [view, setView] = useState('customers'); // 'customers' or 'employees'
    const [customers, setCustomers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const stages = ['طلبية اولية', 'طلبية مؤكدة', 'ارسال العربون', 'التشيك والمطابقة', 'الاستلام', 'الشحن', 'الاوراق', 'تصفية الحسابات', 'ارسال المستندات'];

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    useEffect(() => {
      const fetchCustomers = async () => {
        try {
          const response = await axios.get('https://infinite-eg-backend.vercel.app/api/customers');
          setCustomers(response.data);
        } catch (error) {
          console.error('Error fetching customers:', error);
        }
      };
  
      const fetchEmployees = async () => {
        try {
          const response = await axios.get('https://infinite-eg-backend.vercel.app/api/users?role=employee');
          setEmployees(response.data);
        } catch (error) {
          console.error('Error fetching employees:', error);
        }
      };
  
      fetchCustomers();
      fetchEmployees();
    }, []);
  
    const filteredCustomers = customers.filter((customer) =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const filteredEmployees = employees.filter((employee) =>
      employee.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleDeleteUser = async (userId) => {
      const confirmDelete = window.confirm("هل أنت متأكد من أنك تريد حذف الموظف؟");
      if (confirmDelete) {
        try {
          await axios.delete(`https://infinite-eg-backend.vercel.app/api/users/${userId}`);
          setEmployees(prevEmployees => prevEmployees.filter(employee => employee._id !== userId));
          alert("تم حذف الموظف بنجاح");
        } catch (error) {
          console.error('Error deleting user:', error);
          alert("حدث خطأ أثناء حذف الموظف");
        }
      }
    };    

    return (
      <>
        <CustomAppBar classes={classes} userRole={'manager'} />
        <div className={classes.content} />
        <Grid container spacing={3} direction='rtl'>
          <Grid item xs={12}>
            <Button style={{ fontSize: '1rem', fontWeight: 'bold',color: 'black', textAlign: 'center' }} onClick={() => setView('customers')}>عرض العملاء</Button>
            <Button style={{ fontSize: '1rem', fontWeight: 'bold',color: 'black', textAlign: 'center' }} onClick={() => setView('employees')}>عرض الموظفين</Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              dir='rtl'
              variant="outlined"
              placeholder={view === 'customers' ? "بحث باسم العميل" : "بحث باسم الموظف"}
              className={classes.searchBar}
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                className: classes.searchInput,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} dir='rtl'>
              <Table className={classes.table} aria-label={view === 'customers' ? "stages table" : "employees table"}>
                <TableHead>
                  <TableRow>
                    {view === 'customers' ? (
                      <>
                      <TableCell className={classes.tableHeaderCell}>العميل</TableCell>
                      {stages.map((stage) => (
                        <TableCell align="center" key={stage} className={classes.tableHeaderCell}>
                          {stage}
                        </TableCell>
                      ))}
                      </>
                    ) : (
                      <>
                        <TableCell className={classes.tableHeaderCell}>الموظف</TableCell>
                        <TableCell className={classes.tableHeaderCell}>العمليات</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {view === 'customers' ? (
                filteredCustomers.map((customer) => (
                  <TableRow key={customer._id}>
                    <TableCell style={{ fontSize: '1rem', fontWeight: 'bold', border: '1px solid #ddd', backgroundColor: '#859cc8', color: 'white', textAlign: 'center' }} component="th" scope="row">
                      {customer.name}
                    </TableCell>
                    {stages.map((stageName) => {
                      const stage = customer.stages.find((s) => s.stageName === stageName);
                      const isCurrentStage = customer.stage === stageName;
                      return (
                        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold', border: '1px solid #ddd' }} align="center" key={stageName}>
                          <div className={classes.progressCell}>
                            {stage && stage.employees.map((employee) => (
                              <Chip
                                label={employee.username || ''}
                                className={classes.chip}
                                key={employee._id}
                              />
                            ))}
                            {isCurrentStage && (
                              <div className={classes.progress}>
                                <LinearProgress variant="determinate" value={customer.progress || 0} />
                                <Typography variant="body2">{customer.progress || 0}%</Typography>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
                  ) : (
                    filteredEmployees.map((employee) => (
                      <TableRow key={employee._id}>
                        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold', border: '1px solid #ddd', backgroundColor: '#859cc8', color: 'white', textAlign: 'center' }}>{employee.username}</TableCell>
                        <TableCell>
                        <IconButton onClick={() => navigate(`/edit-employee/${employee._id}`)}>                            <EditIcon />
                          </IconButton>
                          <IconButton style={{backgroundColor:'#b30000'}} onClick={() => handleDeleteUser(employee._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  }
  
  export default ManagerDashboard;