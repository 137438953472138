import React from 'react';
import EmployeeDashboard from '../components/Dashboard/EmployeeDashboard';

function EmployeePage() {
  return (
    <div>
      <EmployeeDashboard />
    </div>
  );
}

export default EmployeePage;
