import React from 'react';
import Container from '@material-ui/core/Container';
import ManagerDashboard from '../components/Dashboard/ManagerDashboard';

function ManagerPage() {
  return (
    <Container>
      <ManagerDashboard />
    </Container>
  );
}

export default ManagerPage;
