import React, { useState, useEffect } from 'react';
import { makeStyles,Button, Typography, TextField,CircularProgress, Box ,Grid,Container,} from '@material-ui/core';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit'; 
import { useNavigate } from 'react-router-dom';
import CustomAppBar from '../../AppBar';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
      </Box>
    </Box>
  );
}

function EmployeeDashboard() {
  const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderRadius: theme.shape.borderRadius, // Add rounded corners to the table
        overflow: 'hidden', // Hide any content that overflows the table
      },
      appBar: {
        marginBottom: theme.spacing(3),
        width: '100vw', // Make the AppBar take the full width of the screen
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Set a transparent color
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a bottom shadow
        color: theme.palette.getContrastText('#2196F3'),
        position: 'fixed', // Fix the AppBar at the top
        left: 0, // Align the AppBar to the left edge
      },
      content: {
        paddingTop: theme.spacing(10), // Add space between AppBar and table
      },
      background: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundImage: 'url(/background.jpg)',
        backgroundSize: 'cover',
        zIndex: -1, // Place it behind other content
      },
      logo: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      card: {
        padding: theme.spacing(3),
        textAlign: 'center', // Center align text in the card
        borderRadius: theme.shape.borderRadius, // Round the corners of the card
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      cardContent: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10), // Add bottom padding
      },
      progressCell: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
      chip: {
        margin: theme.spacing(0.5),
        transition: '0.2s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
      tableHeaderCell: {
        fontSize: '1rem',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        borderRadius: theme.shape.borderRadius, // Add rounded corners to the table header cells
      },
      appbarButton: {
        padding: '6px 12px',
        fontSize: '1.2rem',
        color: 'black',
        background: 'none',
        textDecoration: 'none',
        marginLeft: theme.spacing(1),
        transition: 'color 0.3s',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      progress: {
        flexGrow: 1,
      },
      list: {
        listStyle: 'none',
        padding: 0,
        maxWidth: 600,
        margin: 'auto',
        paddingTop: theme.spacing(10),
      },
      listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '&:last-child': {
          marginBottom: 0,
        },
      },
      customerInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
      },
      button: {
        fontSize:'1.1rem',
        margin: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        marginTop: theme.spacing(2),
        textTransform: 'none', // Prevent uppercase transformation
        fontWeight: 'bold',
      },
      circularProgress: {
        position: 'relative',
        marginBottom: theme.spacing(2),
      },
      progressLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      formContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%', // Or you could give a specific width
        maxWidth: '360px', // Set a max-width for larger screens
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
      },
      input: {
        width: '100%',
        margin: theme.spacing(1, 0),
      },
      submitButton: {
        fontWeight:'bold',
        fontSize: '1.2rem' ,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1.5),
        width: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.primary.dark,
        },
      },
      loginLogo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%', 
      },
      container: {
        height: '100vh', // Use 100% of the viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
      },
      paper: {
        padding: theme.spacing(4),
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          width: '60%', // Set a max width for larger screens
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      searchBar: {
        marginBottom: theme.spacing(3),
        maxWidth: '600px', // Set a max-width for the search bar
        margin: '0 auto', // Center the search bar
        display: 'flex', // Use flex layout
        alignItems: 'center', // Vertically center align items
        borderRadius: theme.shape.borderRadius, // Rounded corners for the input
        background: theme.palette.common.white,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: theme.palette.primary.main, // Border color when the component is idle
          },
          '&:hover fieldset': {
            borderColor: theme.palette.primary.dark, // Border color when hovered
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main, // Border color when the component is focused
          },
        },
      },
      searchInput: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
      },
      
    }));
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const filteredCustomers = customers.filter((customer) =>
  customer.name.toLowerCase().includes(searchTerm.toLowerCase())
).reverse();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('https://infinite-eg-backend.vercel.app/api/customers');
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);
  
  return (
    <>
      <CustomAppBar classes={classes} userRole={'employee'}/>
      <Container maxWidth="md" className={classes.content}>
      <Grid item xs={12}>
          <TextField
            dir='rtl'
            variant="outlined"
            placeholder="بحث باسم العميل"
            className={classes.searchBar}
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            InputProps={{
              className: classes.searchInput,
            }}
          />
        </Grid>
        <Grid container spacing={3} direction="row-reverse">
          {filteredCustomers.map((customer) => (
            <Grid item xs={12} sm={6} md={4} key={customer._id}>
              <Box className={classes.card} boxShadow={3}>
                <Typography variant="h5" gutterBottom>
                اسم العميل : {customer.name}
                </Typography>
                <Typography variant="subtitle1" style={{fontSize: '1.2rem'}}>
                الحالة : {customer.stage}
                </Typography>
                <Typography variant="subtitle1" style={{fontSize: '1.2rem'}}>
                التاريخ : {new Date(customer.date).toLocaleDateString('en-CA')}
                </Typography>
                <Box className={classes.circularProgress}>
                  <CircularProgressWithLabel value={customer.progress} size={80} thickness={5} />
                  <Typography variant="caption" component="div" className={classes.progressLabel}>
                    {`${Math.round(customer.progress)}%`}
                  </Typography>
                </Box>
                <Button
                  className={classes.button}
                  startIcon={<EditIcon />} // Add the edit icon to the button
                  onClick={() => navigate(`/edit-customer/${customer._id}`)}
                >
                  تعديل
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default EmployeeDashboard;
